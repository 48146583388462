@font-face {
    font-family: Dot Font;
    src: url(../../../fonts/dotfont.ttf) format('truetype');
  }

.toolbar-btn-wrap {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
}

.toolbar-wrapper {
    /* position: fixed; */
    width: 100%;
    left: 0;
    bottom: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(129,129,129,1) 0%, rgba(210,210,210,1) 15%, rgba(245,245,245,1) 50%, rgba(210,210,210,1) 85%, rgb(129, 129, 129) 100%);    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    /* width: 400px */
    /* border-top: 4px inset #c6d1bc; */
    /* border-bottom: 4px inset #c6d1bc; */
}

.toolbar-min-wrap {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(129,129,129,1) 0%, rgba(210,210,210,1) 15%, rgba(245,245,245,1) 50%, rgba(210,210,210,1) 85%, rgb(129, 129, 129) 100%);    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 36px;
}

.min-btn {
    height: 24px;
    border-radius: 20px;
    width: 50px;
    min-width: 50px;
    margin: auto 5px;
    line-height: 0;
    font-size: 16px;
}

.min-btn:hover {
    color: white;
    background: goldenrod;
}

.title-wrapper-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.title-text-toolbar {
    font-family: "Dot Font", monospace;
    font-size: 18px;
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: row;
    margin: 0;
    white-space: nowrap;
    /* text-shadow: 0 0 2px #5a5a5a, 2px 2px 16px hsla(0, 0%, 100%, 0.808); */
    /* margin: 2px auto; */
}

.dashed {
    width: 100%;
    border: 1px dashed rgb(66, 66, 255);
    height: 0;
}

.center {
    margin: auto;
}

.trans {
    transform: translate(0px, 40%);
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    /* padding: 10px; */
}

.bolt-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
}

.bolt {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    /* margin: 10px; */
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    background: url(../../../images/bolthead.png);
    background-position: center;
    background-size: contain;
    filter: drop-shadow(-1px 1px 0 rgba(0,0,0,.5));    

    /* background: rgb(236,236,236); */
    /* background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgba(255, 237, 103, 1) 38%, rgb(121, 121, 121) 93%); */
}

.screw {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    /* margin: 10px; */
    /* border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2); */

    /* filter: drop-shadow(-1px 1px 0 rgba(0,0,0,.5));     */
    /* background: rgb(236,236,236);
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(255, 243, 149) 38%, rgb(121, 121, 121) 93%); */
    background: url(../../../images/screwhead.png);
    background-position: center;
    background-size: contain;
}


.settings-wrapper {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.switches-wrapper {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    gap: 10px;
    margin: 10px 0;
    width: 100%;
}

.toolswitch-wrapper {
    width: 100%;
    /* margin: auto; */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.toolswitch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.switch-title {
    font-family: "Dot Font", monospace;
    font-size: 16px;
    color: rgb(0, 0, 0);
    text-shadow: 0 0 2px #ffffff, 2px 2px 16px hsla(0,0%,100%,.5);
    margin: 0;
}

.switch-btn {
    height: 24px;
    border-radius: 20px;
    width: 50px;
    margin: 0 auto;
    line-height: 0;
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .switch-title {
        font-size: 12px;
    }
}

@media screen and (max-width: 535px) {
    .switch-btn {
        width: 35px;
    }

    .min-btn {
        min-width: 35px;
        width:10px;

        padding: 0;
        margin: auto 0;
    }

    .switches-wrapper {
        flex-wrap: wrap;
    }

    .toolswitch-wrapper {
        width: auto;
    }

}