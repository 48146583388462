.mac-programface {
    width: fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: fit-content;
    border-radius: 10px;
}

.above {
    z-index: 3 !important;
}
 
.mac-shadow {
    border-radius: 10px;
    /* box-shadow: -7px 4px rgba(0, 0, 0, 0.425); */
 }

.mac-handle {
    font-family: 'Courier New', Courier, monospace;
    background: linear-gradient(to bottom, rgb(245, 245, 245) 20%, rgb(167, 167, 167) 80%);
    display: flex;
    user-select: none;
    flex-direction: row;
    box-sizing: border-box;
    cursor: move;
    margin: 0px;
    width: 100%;
    padding: 4px 0 4px 6px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.m-minimize-btn {
    width: 18px;
    height: 18px;
    margin: auto 0px auto 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-top: 2px solid rgba(0, 0, 0, 0.253);
    border-left: 2px solid rgba(0, 0, 0, 0.253);
    border-right: 2px solid rgba(0, 0, 0, 0.253);
}

.btn-g {
    background: radial-gradient(white, green);
}

.btn-r {
    background: radial-gradient(white, red);
}

.btn-y {
    background: radial-gradient(rgb(247, 247, 247), yellow);
}

.btn-b {
    background: radial-gradient(rgb(213, 254, 255), rgb(141, 226, 255));
}

.m-minimize-btn:hover {
    cursor: pointer;
}

.mac-program-wrapper {
    margin: 0;
    border: 1px solid rgb(177, 177, 177);
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-bottom-left-radius: 20px;
    width: 100%;
}

.m-title-wrap {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
}

.m-program-icon {
    color: rgb(130, 247, 255);
    font-size: 20px;
    margin: auto 0 auto 5px;
}

.m-program-title {
    color: rgb(0, 0, 0);
    font-size: 14px;

    margin: 7px 0px 7px 5px;
    font-weight: 600;
}

.under-bar {
    font-family: 'Courier New', Courier, monospace;
    background: rgb(255, 255, 255);
    display: flex;
    user-select: none;
    flex-direction: row;
    box-sizing: border-box;
    cursor: move;
    margin: 0px;
    width: 100%;
    /* max-width: 100%; */
    padding: 4px 0 4px 6px;
    font-size: 12px;
    justify-content: center;
    font-style: italic;
    border-top: 1px solid rgba(0, 0, 0, 0.342);
    border-bottom: 1px solid rgba(0, 0, 0, 0.342);
    box-sizing: border-box;
}

.under-bar-text {
    text-wrap: wrap;
    padding: 0;
    text-align: center;
}