@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Monofett&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

.mform-programface {
    width: fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: fit-content;
}

.mform-handle {
    font-family: Microsoft;
    background: rgb(6, 21, 127);
    display: flex;
    user-select: none;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    margin: 0px;
    width: 100%;
    border: 3px solid rgb(177, 177, 177);
}

.mf-minimize-btn {
    width: 24px;
    height: 18px;
    background: radial-gradient(orange, red);
    margin: auto 10px auto 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.mform-program-wrapper {
    margin: 0;
    border-left: 4px solid rgb(177, 177, 177);
    border-bottom: 4px solid rgb(177, 177, 177);
    border-right: 4px solid rgb(177, 177, 177);
    overflow: scroll;
}

.mf-title-wrap {
    display: flex;
    flex-direction: row;
}

.mf-program-icon {
    color: white;
    margin: auto 0 auto 5px;
}

.mf-program-title {
    color: white;
    font-size: 14px;

    margin: 7px 0px 7px 5px;
    font-family: Microsoft, 'Courier New', Courier, monospace;
}

.form-wrapper {
    background: radial-gradient(rgb(197, 189, 74), blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 30px 20px 20px;
    gap: 10px;
    width: 400px;
    height: fit-content;
}


.form-title {
    font-family: 'Monofett', monospace;
    color:lightblue;
    text-shadow: 0 0 2px #000000, 2px 2px 16px hsla(0, 0%, 99%, 0.5);
    margin: 0 auto;
    animation: showoff-form-title 2s alternate-reverse infinite;
    /* transition: transform; */
    transform: rotateX(30deg);
    font-weight: 400;
}

.form-submitted {
    font-family: 'Monofett', monospace;
    color: #89ff71;
    text-shadow: 0 0 2px #000000, 2px 2px 16px hsla(0, 0%, 99%, 0.5);
    margin: 0 auto;
    animation: showoff-form-title 2s alternate-reverse infinite;
    /* transition: transform; */
    transform: rotateX(30deg);
    font-weight: 400;
}

.form-submitted-good-job {
    font-family: Microsoft, 'Courier New', Courier, monospace;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 600;
    color: pink;
    background-color: black;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
}

.form-submitted-p {
    font-family: Microsoft, 'Courier New', Courier, monospace;
    color: white;
    text-align: center;
    font-size: 12px;
    margin: 0;
    
}

@keyframes showoff-form-title {
    0% {
       transform: rotateY(45deg);
    }
    50% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(-45deg);
    }
 }
.names {
    /* font-family: Microsoft, 'Courier New', Courier, monospace; */
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.name-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.italics {
    font-style: italic;
}

.blue-span {
    color: #40e9ff;
}

.green-span {
    color: #89ff71;
}

.yellow-span {
    color: yellow;
    font-style: italic;
    letter-spacing: 1.5px;
}

.pink-span {
    color: #ffc7f8;
    letter-spacing: 2px;
}

.red-span {
    color: #ff5555;
    text-shadow: 1px 1px 2px rgb(255, 73, 73);
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 36px;
    font-family: 'Italianno', cursive;
}

.input-tag {
    font-size: 16px; 
    color: white;
    text-shadow: 0 0 4px #ffd89a, 2px 2px 16px hsla(0,0%,100%,.5);
    margin: 0;
}


.input {
    width: 100%;
    font-family: Microsoft, 'Courier New', Courier, monospace;
    border-style: inset;
}

.message-input {
    width: 100%;
    height: 100px;
    border-style: inset;
}

.send-button {
    width: 20%;
    margin: 0 0 0 auto;
}

.required-text {
    color: red;
    font-size: 14px;
    text-shadow: 0 0 4px #ffe346, 2px 2px 16px hsla(0,0%,100%,.5);
    margin: 0;
}

.input-div {
    width: 100%;
}

.windomf-item:hover {
    body {
        filter: grayscale(0.8);
    }
}

@media screen and (max-width: 600px) {
    .form-wrapper {
        width: 300px;
    }
 }